<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.4vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-card-title
      class="d-flex justify-center ma-0 pa-0"
      :style="{fontWeight: 'bold'}"
    >
      Venda
    </v-card-title>
    <v-row
      no-gutters
      :style="{height: '88%'}"
      class=""
    >
      <v-col>
        <v-row
          no-gutters
        >
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 5"
            class="d-flex align-center"
          >
            Utiliza preço de venda por loja: 
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 5"
            class="" 
          >
            <v-switch
              inset
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza preço de custo por loja: 
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class=""
          >
            <v-switch
              inset
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Utiliza vendedor obrigatório na venda: 
          </v-col>

          <v-col
            v-show="$vssWidth < 600"
            sm="1" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"    
            class="pt-3"        
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - É obrigatório selecionar um vendedor para finalizar a venda. <br>
                Não - Não precisa de vendedor para finalizar a venda.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - É obrigatório selecionar um vendedor para finalizar a venda. <br>
                Não - Não precisa de vendedor para finalizar a venda.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Utiliza cliente obrigatório na venda:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon 
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - É obrigatório selecionar um cliente para finalizar a venda. <br>
                Não - Não precisa de cliente para finalizar a venda.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - É obrigatório selecionar um cliente para finalizar a venda. <br>
                Não - Não precisa de cliente para finalizar a venda.
              </span>
            </v-tooltip>
          </v-col>         
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            
            class="d-flex align-center"
          >
            Bloqueia a alteração de quantidade do produto:
          </v-col>
          <v-col
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>

          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Permite alterar valor total dos produtos na venda:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Permite alterar o valor total dos produtos na venda. <br>
                Não - Não permite alterar o valor total dos produtos na venda.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Permite alterar o valor total dos produtos na venda. <br>
                Não - Não permite alterar o valor total dos produtos na venda.
              </span>
            </v-tooltip>
          </v-col>            
        </v-row>        
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza mesa:
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Solicita motivo de desistência do produto:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Solicita motivo da desistência/retirada do produto. <br>
                Não - Não solicita motivo da desistência/retirada do produto.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  :style="{paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : ''}"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Solicita motivo da desistência/retirada do produto. <br>
                Não - Não solicita motivo da desistência/retirada do produto.
              </span>
            </v-tooltip>
          </v-col>  
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza comanda:
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center "
          >
            Solicita motivo de cancelamento da venda:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Solicita motivo do cancelamento da venda. <br>
                Não - Não solicita motivo do cancelamento da venda.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  :style="{paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : ''}"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Solicita motivo do cancelamento da venda. <br>
                Não - Não solicita motivo do cancelamento da venda.
              </span>
            </v-tooltip>
          </v-col>  
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"            
          >
            Permite uso de vale troca gerado em todas as lojas:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Permite que o vale troca seja utilizado em outras lojas. <br>
                Não - Permite que o vale troca seja utilizado APENAS na loja em que foi gerado.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"
                  class="mb-2 pl-7"
                  v-bind="attrs"
                  :style="{paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : ''}"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Permite que o vale troca seja utilizado em outras lojas. <br>
                Não - Permite que o vale troca seja utilizado APENAS na loja em que foi gerado.
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Quantidade de dias para vencimento do vale troca:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8" 
            class="pt-2"
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse    
                :style="{width: $vssWidth >= 1264 ? '100%' : '100%'}"   
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza brinde por valor:
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Valor mínimo recebimento brinde:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse    
                :style="{width: $vssWidth >= 1264 ? '100%' : '100%'}"         
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Quantidade de casas para arredondamento: 
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8" 
            class="pt-2"
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse    
                :style="{width: $vssWidth >= 1264 ? '100%' : '100%'}"          
              />
            </v-form>
          </v-col>
          <v-col />
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Valor mínimo de parcela no cartão de crédito:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                type="number"
                value=""    
                outlined
                dense
                flat 
                reverse    
                :style="{width: $vssWidth >= 1264 ? '100%' : '100%'}"          
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Permite retirada estoque zerado:
          </v-col>
          <v-col
            sm="3"
            md="3"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['Permite retirada', 'Permite retirada com aviso', 'Não permite retirada']"
              :style="{width: '100%'}" 
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title
          class="d-flex justify-center ma-0 pa-0"
          :style="{fontWeight: 'bold'}"
        >
          Desconto
        </v-card-title>
        
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Libera desconto no total da venda para usuário operacional:
          </v-col>
          <v-col
            sm="2"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8" 
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Percentual permitido para desconto no total da venda:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                value=""    
                type="number"
                outlined
                dense
                flat                     
                :style="{width: $vssWidth >= 1264 ? '100%' : '100%'}"          
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Permite desconto no item da venda para usuário operacional:
          </v-col>
          <v-col
            sm="3"
            md="3"
            :cols="$vssWidth < 600 ? 4 : 8" 
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['Permite desconto no item', 'Permite desconto com aviso', 'Não permite desconto no item']"
              :style="{width: '100%'}" 
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title
          class="d-flex justify-center ma-0 pa-0"
          :style="{fontWeight: 'bold'}"
        >
          Crediário
        </v-card-title>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="d-flex align-center"
          >
            Senha para finalizar venda crediário:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sempre solicitar senha para finalizar venda em crediário. <br>
                Validar dados do cliente (Ex.: renda disponível) <br>
                Nunca solicitar senha e validação para finalizar venda em crediário.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="3"
            md="3"
            :cols="$vssWidth < 600 ? 4 : 8"
          >
            <v-select
              :style="{width: '100%'}" 
              dense
              outlined
              hide-details
              :items="['Sempre', 'Somente se tiver alguma regra de validação que não passou', 'Nunca']"
            /> 
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  :style="{paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : ''}"
                  class="pl-7"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sempre solicitar senha para finalizar venda em crediário. <br>
                Validar dados do cliente (Ex.: renda disponível) <br>
                Nunca solicitar senha e validação para finalizar venda em crediário.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Solicita senha para pagamento parcial ou desconto no recebimento do crediário: 
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8" 
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      valid: true,
    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>